import { atom } from "recoil";

export const makeDeliveryDrawerIsOpenState = atom({
   key: 'makeDeliveryDrawerIsOpenState',
   default: false
})


type shipper = {
   id: number,
   displayName: string,
   type: string
}
export const shipperListState = atom({
   key: 'shipperListState',
   default: [] as shipper[]
})

export const containerStatus = atom({
   key: 'containerStatus',
   default: [
      { value: 1, label: 'Pending' },
      { value: 2, label: 'In-housed' },
      { value: 3, label: 'Delivered' },
   ]
})

export const instructionOptionState = atom({
   key: 'instructionOptionState',
   default: [
      { value: 'P/U FROM PORT', label: 'P/U FROM PORT' },//从码头拿货
      { value: 'P/U FROM WHS/CFS', label: 'P/U FROM WHS/CFS' },//从其他仓库拿货
      { value: 'SEND TO EDGY', label: 'SEND TO EDGY' },//直接送到仓库
      { value: 'FULL CNTR DELIVERY', label: 'FULL CNTR DELIVERY' },//直接派送到目的地
      { value: 'PARTIALY DVN/DEL', label: 'PARTIALY DVN/DEL' },//一部分留在仓库，其他送走
   ]
})

export const shipViaState = atom({
   key: 'shipViaState',
   default: [
      { value: 'LCL OCEAN', label: 'LCL OCEAN' },
      { value: 'FCL OCEAN', label: 'FCL OCEAN' },
      { value: 'AIR', label: 'AIR' },
   ]
})
export const isLoginState = atom({
   key: 'isLoginState',
   default: false
})
export const userInfoState = atom({
   key: 'userInfoState',
   default: {} as any
})

export const menu =['Schedules','Pending', 'In-housed', 'Delivered','Archived']

export const roleState = atom({
   key: 'roleState',
   default: ''
})