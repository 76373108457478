import { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AutoComplete, Button, Col, Divider, Form, Row, Select, Switch, message } from "antd";
import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons';
import {
    DrawerForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText,
    ProFormTimePicker,
} from '@ant-design/pro-form';
import axios from "axios";
import { containerPageActionListState, devanningDrawerIsOpenState, fillPalletsModelIsOpenState, focusContainerRecordState, palletsOfDevannedDrawerState } from "./recoil.state";
import { devannedItemList, insertDevannedUrl } from "./api";
import { ContainerAction, POTableDataType } from "./type";
import dayjs from 'dayjs';

export const DevanningDrawer = () => {
    const [totalBox, setTotalBox] = useState(0);
    const [POs, setPOs] = useState([{ PO: '', loadedQTY: 0, palletization: 0, pallets: 0, totalQTY: 0, customer: '' }]);
    const [totalBoxes, setTotalBoxes] = useState(0);
    const [form] = Form.useForm();
    const [formLoading, setFormLoading] = useState(false)
    const [devanningIsOpen, setDevanningIsOpen] = useRecoilState(devanningDrawerIsOpenState);
    const focusRecord = useRecoilValue(focusContainerRecordState);
    const [fillPalletsModelIsOpen, setFillPalletsModelIsOpen] = useRecoilState(fillPalletsModelIsOpenState)
    const [palletsOfDevannedDrawer, setPalletsOfDevannedDrawer] = useRecoilState(palletsOfDevannedDrawerState)
    const [containerPageActionList, setContainerPageActionList] = useRecoilState(containerPageActionListState);
    const [locations, setLocations] = useState([])


    const title = `Devanning #${focusRecord.containerId}`

    const devanningSaveConfirm = async (values: any) => {
        setFormLoading(true)
        var containerData = { ...values }
        containerData.shipper = focusRecord.shipper
        containerData.containerId = focusRecord.containerId
        delete containerData.POs
        delete containerData._id
        const devannedData = values.POs.map(it => {
            delete it._id
            return {
                ...containerData, ...it
            }
        })
        axios.post(insertDevannedUrl, {
            devannedData,
            devannedDate: values.devannedDate,
            containerId: focusRecord._id
        }).then((res) => {
            message.success('submit success');

            //更新表格
            var containerPageActions: ContainerAction[] = JSON.parse(JSON.stringify(containerPageActionList));
            containerPageActions.push('uploadExcel')
            setContainerPageActionList(containerPageActions)
        }).catch(e => {

        }).finally(() => {
            setFormLoading(false)

        })
        return true
    }

    useEffect(() => {
        if (!focusRecord.containerId) {
            return
        }
        form.setFieldsValue({ ...focusRecord })
        form.setFieldValue('devannedDate', dayjs())

        var totalBXData = totalBoxes
        var POsData = JSON.parse(JSON.stringify(focusRecord.POs))
        setFormLoading(true)
        POsData = POsData.sort((a, b) => {
            if (a.customer == b.customer) {
                return a.PO.localeCompare(b.PO)
            } else {
                return a.customer.localeCompare(b.customer)
            }
        })
        POsData = POsData.map((it: POTableDataType, index) => {
            totalBXData += Number(it.loadedQTY)
            return it
        })

        setTotalBox(totalBXData)
        form.setFieldValue('POs', POsData)
        setFormLoading(false)

        axios.get(devannedItemList).then(res => {
            setLocations(res.data.locations.map(it => ({ label: it, value: it })))

        }).catch(e => {

        })


    }, [devanningIsOpen])

    useEffect(() => {
        if (!devanningIsOpen) {
            return
        }

        var POs = form.getFieldValue('POs')
        var palletsList = [...palletsOfDevannedDrawer]
        POs = POs.map(it => {
            if (palletsList.length > 0) {
                it.pallets = palletsList.shift()
            } else {
                delete it.pallets
            }
            return it
        })
        try {
            form.setFieldValue('POs', POs)
        } catch (e) {
            message.error('filled pallets error')
        }
    }, [palletsOfDevannedDrawer])


    const openChange = (t) => {
        setDevanningIsOpen(t)
    }
    const fillPalletsClick = (t) => {
        setFillPalletsModelIsOpen(true)
    }

    return <DrawerForm
        loading={formLoading}
        width={1400}
        // onFieldsChange={()=>{}}
        onOpenChange={t => openChange(t)}
        title={title}
        form={form}
        open={devanningIsOpen}
        onFinish={devanningSaveConfirm}
        submitter={{
            searchConfig: {
                submitText: 'Confirm',
                resetText: 'Cancel',
            },
        }}
        drawerProps={{
            extra:
                <Button type="primary" onClick={fillPalletsClick} >
                    fillPallets
                </Button >

        }}
    >
        <Row>
            <Col>
                <ProFormText
                    style={{ padding: 0 }}
                    colProps={{ sm: 3 }}
                    name="operator"
                    label="Operator"
                    placeholder={focusRecord.operator}
                    initialValue={focusRecord.operator}
                />
            </Col>
            <Col>
                <ProFormText
                    style={{ padding: 0 }}
                    colProps={{ sm: 4 }}
                    name="billNumber"
                    label="Bill#"
                    placeholder={focusRecord.billNumber}
                    initialValue={focusRecord.billNumber}
                />
            </Col>
            <Col>
                <ProFormDatePicker name="devannedDate" label="Devanned Date" rules={[{ required: true }]} initialValue={dayjs()} fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }} />
            </Col>

            <Col span={4}>
                <Form.Item
                    label="Location"
                    name="location"
                    rules={[{ required: true, message: 'Please input location' }]}
                >
                    <AutoComplete
                        allowClear
                        style={{fontSize: '16px !important'}}
                        options={locations}
                    />
                </Form.Item>
            </Col>
        </Row>



        <ProFormList
            name="POs"
            creatorButtonProps={{
                creatorButtonText: 'Add Item',
            }}
            min={1}
            // creatorRecord={{ name: '', items: [{ name: '' }] }}
            initialValue={POs}
            copyIconProps={{ Icon: CopyOutlined, tooltipText: 'Copy' }}
            deleteIconProps={{
                Icon: CloseCircleOutlined,
                tooltipText: 'Delete',
            }}
        >
            <ProFormGroup key="group">
                <Row>
                    <Col span={4}><ProFormText
                        style={{ padding: 0 }}
                        width="md"
                        key={'customer'}
                        name="customer"
                        label="Customer"
                        placeholder={''}
                    /></Col>
                    <Col span={4}><ProFormText
                        style={{ padding: 0 }}
                        width="md"
                        key={'PO'}
                        name="PO"
                        label="PO#"
                        placeholder={''}
                    /></Col>
                    <Col span={2}><ProFormDigit
                        style={{ padding: 0 }}
                        width="md"
                        key={'loadedQTY'}
                        name="loadedQTY"
                        label="LoadedQTY"
                        placeholder={''}
                    /></Col>
                    <Col span={2}><ProFormDigit
                        style={{ padding: 0 }}
                        width="md"
                        key={'totalQTY'}
                        name="totalQTY"
                        label="TotalQTY"
                        placeholder={''}
                    /></Col>
                    <Col span={2} offset={0}><ProFormDigit
                        style={{ padding: 0 }}
                        width="md"
                        key={'pallets'}
                        name="pallets"
                        label="Pallets"
                        placeholder={''}
                        rules={[{ required: true }]}
                    /></Col>
                    <Col span={4}>
                        <ProFormDatePicker name="deliveryDate" label="Est. Del. Date" fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }} />
                    </Col>
                    <Col >
                        <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                    </Col>
                </Row>
            </ProFormGroup>
        </ProFormList>
        <Divider />
        <Row justify={'end'}>
            <Col><span style={{ fontWeight: 'bold' }}>{`TotalBoxes:${totalBox}`}</span></Col>
        </Row>
    </DrawerForm >
}

