import { useRecoilState } from "recoil";
import { bulkEditIsOpenState, scheduleActionListState, selectedScheduleRecordsState } from "./recoil.state";
import { DrawerForm, ProFormDatePicker, ProFormDigit, ProFormGroup, ProFormList, ProFormText, ProFormTimePicker } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { bulkEditScheduleItemsUrl } from "./api";
import axios from "axios";
import { Col, Form, Row, message } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ScheduleAction } from "./type";



const title = 'Edit'
export const BulkEditItems = () => {
    const [selectedScheduleRecords, setSelectedScheduleRecords] = useRecoilState(selectedScheduleRecordsState);
    const [formLoading, setFormLoading] = useState(false)
    const [bulkEditIsOpen, setBulkEditIsOpen] = useRecoilState(bulkEditIsOpenState)
    const [scheduleActionList, setScheduleActionList] = useRecoilState(scheduleActionListState)
    const [form] = Form.useForm();
    const [selected, setSelected] = useState([])
    useEffect(() => {
        if (selectedScheduleRecords.length == 0) {
            form.setFieldValue('records', [])
            return
        }
        let recds =JSON.parse(JSON.stringify(selectedScheduleRecords))

        form.setFieldValue('records', recds)

    }, [bulkEditIsOpen])

    const bulkEditClick = async (values) => {
        setFormLoading(true)
        let records = form.getFieldValue('records')
        var time = form.getFieldValue('deliveryTime')
        var deliveryDate = form.getFieldValue('deliveryDate')

        time = time?dayjs(time).format('HH:mm'):null
        deliveryDate= deliveryDate?dayjs(deliveryDate).format('MM/DD/YYYY'):null
        records = records.map(it => {
            it.deliveryDate = deliveryDate
            it.deliveryTime = time
            delete it.key
            return it
        })

        axios.put(bulkEditScheduleItemsUrl, {
            records
        }).then(res => {
            message.success('submit success')
            //更新 schedule table data
            var scheduleActions: ScheduleAction[] = JSON.parse(JSON.stringify(scheduleActionList))
            scheduleActions.push('bulkEdit')
            setScheduleActionList(scheduleActions)
        }).catch(e => {

        }).finally(() => {
            setFormLoading(false)
        })
        return true
    }
    return <>{bulkEditIsOpen &&
        <DrawerForm
            loading={formLoading}
            width={1300}
            // onFieldsChange={()=>{}}
            onOpenChange={setBulkEditIsOpen}
            title={title}
            form={form}
            open={bulkEditIsOpen}
            onFinish={bulkEditClick}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
        >

            <Row>
                <Col >
                    <ProFormDatePicker initialValue={dayjs()} fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }} name="deliveryDate" label="Est. Del. Date" />
                </Col>
                <Col >
                    <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                </Col>
            </Row>

            <ProFormList
                name="records"
                min={1}
                copyIconProps={false}
                creatorButtonProps={false}
                // creatorRecord={{ name: '', items: [{ name: '' }] }}
                initialValue={selected}
                deleteIconProps={{
                    Icon: CloseCircleOutlined,
                    tooltipText: 'Delete',
                }}
            >

                <ProFormGroup key="group">
                    <Row>
                        <Col span={4}>
                            <ProFormText
                                disabled
                                width="md"
                                name="billNumber"
                                label="Bill#"
                                placeholder=""
                            // disabled
                            // initialValue={it.billNumber}
                            />
                        </Col>
                        <Col span={4}>
                            <ProFormText
                                width="md"
                                name="customer"
                                label="Customer"
                                placeholder=""
                            // disabled
                            // initialValue={it.vendor}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormText

                                width="md"
                                name="PO"
                                label="PO#"
                                placeholder=""
                            // initialValue={it.PO}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormDigit

                                width="md"
                                name="loadedQTY"
                                label="LoadedQTY"
                                placeholder=""
                            // initialValue={it.loadedQTY}
                            />
                        </Col>
                        <Col span={2}>
                            <ProFormDigit
                                width="md"
                                name="pallets"
                                label="Pallets"
                                placeholder=""
                                disabled
                            // initialValue={it.pallets}
                            />
                        </Col>
                        <Col >
                            <ProFormDatePicker disabled name="deliveryDate" label="Est. Del. Date" fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }}/>
                        </Col>
                        <Col >
                            <ProFormTimePicker disabled fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                        </Col>
                    </Row>

                </ProFormGroup>
            </ProFormList>

        </DrawerForm>}
    </>
}