import { ContainerPage } from "./container/containerpage"
import { DevannedPage } from "./inhoused/inhousedPage"
import { ArchivedPage } from "./archived/archivedpage"
import { DeliveryPage } from "./delivered/deliveredPage"
import { SchedulesPage } from "./schedules/schedulespage"
import { UserPage } from "./user/userPage"
import { DashboardPage } from "./dashboard/dashboardpage"

export const PageShow = (p: { tkey: string }) => {
    switch (p.tkey) {
        case 'Schedules':
            return <SchedulesPage />
        case 'Pending':
            return <ContainerPage />
        case 'In-housed':
            return <DevannedPage />
        case 'Delivered':
            return <DeliveryPage />
        case 'Archived':
            return <ArchivedPage />
        case 'Dashboard':
            return <DashboardPage />
        case 'User':
            return <UserPage />
        default:
            return <></>
    }
}