
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-form';
import { Form, message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import { useEffect } from 'react';
import { focusUserRecordState, userActionListState, userUpdateModelIsOpenState } from './recoil.state';
import { updateUserUrl } from './api';
import { UserAction } from './type';

export const UserUpdateModel = () => {
    const focusUserRecord = useRecoilValue(focusUserRecordState)
    const [userUpdateModelIsOpen, setUserUpdateModelIsOpen] = useRecoilState(userUpdateModelIsOpenState)
    const [userPageActionList, setUserPageActionList] = useRecoilState(userActionListState);

    const [form] = Form.useForm();
    const userUpdate = (values) => {
        axios.put(updateUserUrl, {
            values, 
            id: focusUserRecord._id
        }).then(res => {
            message.success('Update Success')
            //更新表格
            var userPageActions: UserAction[] = JSON.parse(JSON.stringify(userPageActionList));
            userPageActions.push('updateUser')
            setUserPageActionList(userPageActions)
        }).catch(e => {

        }).finally(() => {

        })
    }
    useEffect(() => {
        form.setFieldsValue({ ...focusUserRecord })
    }, [userUpdateModelIsOpen])

    return (
        <ModalForm
            open={userUpdateModelIsOpen}
            title={`UserName-${focusUserRecord.email}`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(p) => setUserUpdateModelIsOpen(p)}
            submitTimeout={2000}
            onFinish={async (values) => {
                userUpdate(values)
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText
                    width="md"
                    name="email"
                    label="UserName"
                    placeholder="UserName"
                />
                <ProFormText
                    width="md"
                    name="password"
                    label="Password"
                    placeholder="Password"
                />
                <ProFormSelect
                    width="md"
                    name="role"
                    label="Role"
                    placeholder="Role"
                    options={[
                        {
                            value: 'admin',
                            label: 'admin',
                        },
                        {
                            value: 'user',
                            label: 'user',
                        },
                    ]}
                />
            </ProForm.Group>
        </ModalForm>
    )
}