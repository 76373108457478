import React, { useEffect, useState } from 'react';
import { Button, Col,  Row, Table,  Upload, UploadProps, message } from 'antd';
import {  deleteContainerUrl, searchContainerUrl, uploadExcel } from './api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containerAddDrawerIsOpen, containerPageActionListState, containerSearchValuesState, pendingTableLoadingState, selectedPendingRecordsState, tableDataState } from './recoil.state';
import { apiHost } from '../apiInfo'
import axios from 'axios';
import { roleState } from '../stateManage';
import { usePageTableColumn } from './hooks';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const ContainerTable: React.FC = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(tableDataState)
    const [isAddDrawerIsOpen, setIsAddDrawerIsOpen] = useRecoilState(containerAddDrawerIsOpen)
    const [pendingTableLoading, setPendingTableLoading] = useRecoilState(pendingTableLoadingState)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedPendingRecords, setSelectedPendingRecords] = useRecoilState(selectedPendingRecordsState);
    const containerSearchValues = useRecoilValue(containerSearchValuesState);
    const [containerPageActionList, setContainerPageActionList] = useRecoilState(containerPageActionListState);
    const columns = usePageTableColumn()
    const navigate = useNavigate()

    const addClick = () => {
        setIsAddDrawerIsOpen(true)
    }

    useEffect(() => {
        setSelectedRowKeys([])
        setPendingTableLoading(true)

        var values = JSON.parse(JSON.stringify(containerSearchValues))
        if (values.ETA) {
            values.ETA = dayjs(values.ETA).format('MM/DD/YYYY')
        }
        if (values.ETD) {
            values.ETD = dayjs(values.ETD).format('MM/DD/YYYY')
        }
        if (values.deliveryDate) {
            let date = {start:null,end:null}
            date.start = dayjs(values.deliveryDate[0]).format('MM/DD/YYYY')
            date.end = dayjs(values.deliveryDate[1]).format('MM/DD/YYYY')
            values.deliveryDate = date
        }
        if (values.deliveryTime) {
            values.deliveryTime = dayjs(values.deliveryTime).format('HH:mm')
        }
        if (values.loadedQTY == "") {
            delete values.loadedQTY
        }
        axios.post(searchContainerUrl, { values }).then(res => {
            var dataRes = res.data.map((it: any) => ({ ...it, key: it._id }))
            dataRes = dataRes.map((it: any) => {
                it.shipmentQTY=it.POs.reduce((acc, cur) => acc + cur.loadedQTY, 0)
                return it
            })
            //sort by ETA
            dataRes.sort((a, b) => {
                //if empty, put it to the end
                if (!a.ETA) {
                    return 1
                }
                if (!b.ETA) {
                    return -1
                }
                return dayjs(a.ETA).isBefore(dayjs(b.ETA)) ? 1 : -1
            })
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setPendingTableLoading(false)
        })
    }, [containerSearchValues, containerPageActionList])

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedPendingRecords(selectedRows)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const importXlsxProps: UploadProps = {
        name: 'containerexcel',
        withCredentials: true,
        action: apiHost + uploadExcel, // 后端上传接口地址
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // message.info(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                //更新表格
                var containerPageActions = JSON.parse(JSON.stringify(containerPageActionList));
                containerPageActions.push('uploadExcel')
                setContainerPageActionList(containerPageActions)
            }else if(info.file.status === 'error') {
                if(info.file.response.statusCode === 401) {
                    navigate('/')
                }else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        },
    };
    const deleteClick = () => {
        axios.post(deleteContainerUrl, {
            ids: selectedPendingRecords.map((it: any) => it._id)
        }).then(res => {
            message.success('delete success')

            //更新表格
            var containerPageActions = JSON.parse(JSON.stringify(containerPageActionList));
            containerPageActions.push('deleteContainer')
            setContainerPageActionList(containerPageActions)
        }).catch(e => {

        }).finally(() => {

        })
    }
    const downloadClick = () => {

    }

    return <>
        <Row justify='end' style={{ margin: '10px', marginRight: 0,display: role != 'customer'? '':'none'}}>
            <Col >

                <Button onClick={addClick} >Add Shipment</Button>
            </Col>
            <Col style={{ marginLeft: '20px',display: role == 'admin'? '':'none'}}>
                <Upload {...importXlsxProps}>
                    <Button >Import Shipment Excel</Button>
                </Upload>
            </Col>

            <Col style={{ marginLeft: '20px',display: role == 'admin'? '':'none' }}>
                <Button onClick={deleteClick} disabled={!hasSelected} >Delete Container</Button>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
                <Button onClick={downloadClick} type="primary" href='/pending_template_2.0.xlsx' icon={<DownloadOutlined />}>Container Template</Button>
            </Col>

        </Row >
        <Table
            sticky={true}
            size={'small'}
            loading={pendingTableLoading}
            pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
            rowSelection={rowSelection}
            rowClassName="editable-row"
            dataSource={tableData} scroll={{ x: 1650, y: 1000 }} columns={columns}></Table>
    </>
};
