import { Button, Col, DatePicker, DatePickerProps, Form, Input, Row, Select, Space, Table, TimePicker,  } from "antd"
import { ContainerTable } from "./schedulesTable"
import { useRecoilState, useRecoilValue } from "recoil"
import { dashboardTableDataState, scheduleSearchValuesState, scheduleTableLoadingState } from "./recoil.state"
import { useEffect, useState } from "react"
import axios from "axios"
import { archivedItemListUrl, devannedItemList, scheduleItemsOfCustomer, searchDashboardUrl } from "./api"
import { SummaryDrawer } from "./summaryDrawer"
import { roleState, shipViaState } from "../stateManage"
import { pendingItemList } from "../container/api"
import { BulkEditItems } from "./bulkEditItems"
import _ from 'lodash'
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
  
export const SchedulesPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(dashboardTableDataState)
    const [scheduleSearchValues, setScheduleSearchValues] = useRecoilState(scheduleSearchValuesState)
    const [scheduleTableLoading, setScheduleTableLoading] = useRecoilState(scheduleTableLoadingState)
    const [shippers, setShippers] = useState([])
    const [containerIds, setContainerIds] = useState([])
    const [customers, setCustomers] = useState([])
    const [bills, setBills] = useState([])
    const [POs, setPOs] = useState([])
    const [locations, setLocations] = useState([])
    const [shipperVias, setShipperVias] = useState([])

    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.loadedQTY = Number(values.loadedQTY)
        var v = _.omitBy(values, v => v == undefined || v == null || v == '')
        setScheduleSearchValues(v)
    }
    useEffect(() => {
        if (role == 'admin') {
            axios.get(devannedItemList).then(res => {
                setLocations(res.data.locations.map(it => ({ label: it, value: it })))
            }).catch(e => { })
            axios.get(archivedItemListUrl).then(resArchivedItemList => {
                var dataArchived = resArchivedItemList.data
                axios.get(pendingItemList).then(res => {
                    var { data } = res
                    data.containerIds = data.containerIds.concat(dataArchived.containerIds)
                    data.customers = data.customers.concat(dataArchived.customers)
                    data.bills = data.bills.concat(dataArchived.bills)
                    data.shipperVias = data.shipperVias.concat(dataArchived.shipperVias)
                    if (role == 'admin') {
                        data.shippers = data.shippers.concat(dataArchived.shippers)
                    }
                    data.POs = data.POs.concat(dataArchived.POs)

                    for (let key in data) {
                        data[key] = data[key].filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        });
                    }
                    setShipperVias(data.shipperVias.map(it => ({ label: it, value: it })))
                    setContainerIds(data.containerIds.map(it => ({ label: it, value: it })))
                    setCustomers(data.customers.map(it => ({ label: it, value: it })))
                    setBills(data.bills.map(it => ({ label: it, value: it })))
                    if (role == 'admin') {
                        setShippers(data.shippers.map(it => ({ label: it, value: it })))
                    }
                    setPOs(data.POs.map(it => ({ label: it, value: it })))

                }).catch(e => {

                })

            }).catch(e => {

            })
        } else if (role == 'user') {
            axios.get(devannedItemList).then(res => {
                setLocations(res.data.locations.map(it => ({ label: it, value: it })))
            }).catch(e => { })
            axios.get(archivedItemListUrl).then(resArchivedItemList => {
                var dataArchived = resArchivedItemList.data
                axios.get(pendingItemList).then(res => {
                    var { data } = res
                    data.containerIds = data.containerIds.concat(dataArchived.containerIds)
                    data.customers = data.customers.concat(dataArchived.customers)
                    data.bills = data.bills.concat(dataArchived.bills)
                    data.shipperVias = data.shipperVias.concat(dataArchived.shipperVias)
                    data.POs = data.POs.concat(dataArchived.POs)

                    for (let key in data) {
                        data[key] = data[key].filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        });
                    }
                    setShipperVias(data.shipperVias.map(it => ({ label: it, value: it })))
                    setContainerIds(data.containerIds.map(it => ({ label: it, value: it })))
                    setCustomers(data.customers.map(it => ({ label: it, value: it })))
                    setBills(data.bills.map(it => ({ label: it, value: it })))
                    setPOs(data.POs.map(it => ({ label: it, value: it })))

                }).catch(e => {

                })

            }).catch(e => {

            })
        } else if (role == 'customer') {
            axios.get(scheduleItemsOfCustomer).then(res => {
                const data = res.data
                setShipperVias(data.shipperVias.map(it => ({ label: it, value: it })))
                setContainerIds(data.containerIds.map(it => ({ label: it, value: it })))
                setBills(data.bills.map(it => ({ label: it, value: it })))
                setPOs(data.POs.map(it => ({ label: it, value: it })))
            }
            ).catch(e => { })
        } else {

        }

        if (Object.keys(scheduleSearchValues).length > 0) {
            form.setFieldsValue(scheduleSearchValues)
        }

    }, [])
    const resetClick = () => {
        setScheduleSearchValues({})

    }
    const optionsClick = () => {

    }
    var colSpan = 5
    const adminSearchOptions = ['shipper', 'containerId', 'billNumber', 'customer', 'PO', 'loadedQTY', 'deliveryDate', 'deliveryTime', 'shipperVia', 'location']
    var searchOptions = []
    if (role == 'admin') {
        searchOptions = adminSearchOptions
    } else if (role == 'user') {
        _.remove(adminSearchOptions, (it) => it == 'shipper' || it == 'location')
    } else if (role == 'customer') {
        _.remove(adminSearchOptions, (it) => it == 'shipper' || it == 'location' || it == 'customer')
    } else {
        searchOptions = []
    }
    searchOptions = adminSearchOptions
    return <>
        <Form
            labelCol={{ span: 10 }}
            // wrapperCol={{ span: 20 }}
            labelAlign="right"
            name="search"
            onFinish={onFinish}
            form={form}
        >
            <Row>
                <Col span={searchOptions.includes('shipper') ? colSpan : 0} >
                    <Form.Item name="shipper" label="Forwarder">
                        <Select showSearch allowClear={true} options={shippers} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('containerId') ? colSpan : 0} >
                    <Form.Item name="containerId" label="CNTR/AWB#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={containerIds}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('billNumber') ? colSpan : 0} >
                    <Form.Item name="billNumber" label="Bill#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={bills}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('customer') ? colSpan : 0} >
                    <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('PO') ? colSpan : 0}>
                    <Form.Item name="PO" label="PO#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={POs}
                        />
                    </Form.Item>
                </Col>


                <Col span={searchOptions.includes('loadedQTY') ? colSpan : 0}>
                    <Form.Item name="loadedQTY" label="Loaded Q’ty">
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryDate') ? colSpan : 0}>
                    <Form.Item name="deliveryDate" label="Est. Del. Date" >
                        <RangePicker format="MM/DD"/>
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryTime') ? colSpan : 0}>
                    <Form.Item name="deliveryTime" label="Est. Del. Time">
                        <TimePicker format={'HH:mm'} minuteStep={10} changeOnBlur={true} showNow={false} />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('shipperVia') ? colSpan : 0}>
                    <Form.Item name="shipperVia" label="Ship Via">
                        <Select
                            showSearch
                            allowClear={true}
                            options={shipperVias}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('location') ? colSpan : 0}>
                    <Form.Item name="location" label="Location">
                        <Select
                            allowClear={true}
                            options={locations}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" >
                {/* <Col >
                    <Button type="primary" onClick={optionsClick} icon={<SettingOutlined />} size={"middle"} />
                </Col> */}
                <Col  >
                    <Form.Item style={{ marginRight: '20px' }} >
                        <Button type="primary" htmlType="submit">Search</Button >
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item   >
                        <Button onClick={resetClick} type="primary" htmlType="reset">Reset</Button >
                    </Form.Item>
                </Col>

            </Row>
        </Form>
        <BulkEditItems />
        <SummaryDrawer />
        <ContainerTable />
    </>
}