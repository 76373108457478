import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker,
} from '@ant-design/pro-form';
import { Button, Form, Row, Select, TimePicker, message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import { doEditUrl } from './api';
import { useEffect } from 'react';
import { editModelIsOpenState, focusDevannedRecordState, inHousedActionListState } from './recoil.state';
import { InHousedAction } from './type';
import { roleState } from '../stateManage';

export const EditModel = () => {
    const focusDevannedRecord = useRecoilValue(focusDevannedRecordState)
    const [editModelIsOpen, setEditModelIsOpen] = useRecoilState(editModelIsOpenState)
    const [inHousedActionList, setInHousedActionList] = useRecoilState(inHousedActionListState)
    const role = useRecoilValue(roleState)

    const [form] = Form.useForm();
    const saveEdit = (values) => {
        axios.put(doEditUrl, {
            id: focusDevannedRecord._id, values
        }).then(res => {
            message.success('submit success')
             //更新表格
             var inHousedActions:InHousedAction[] = JSON.parse(JSON.stringify(inHousedActionList))
             inHousedActions.push('edit')
             setInHousedActionList(inHousedActions)
        }).catch(e => {

        })
    }
    useEffect(() => {
        form.setFieldsValue({ ...focusDevannedRecord })
    }, [editModelIsOpen])
    return (
        <ModalForm
            open={editModelIsOpen}
            title={`Edit# ${focusDevannedRecord.containerId}`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(b) => setEditModelIsOpen(b)}
            submitTimeout={2000}
            onFinish={async (values) => {
                saveEdit(values)
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText
                    width="md"
                    name="billNumber"
                    label="Bill#"
                    placeholder=""
                    disabled
                    initialValue={focusDevannedRecord.billNumber}
                />
                <ProFormText
                    width="md"
                    name="customer"
                    label="Customer"
                    placeholder=""
                    disabled = {role == 'customer'}
                    initialValue={focusDevannedRecord.customer}
                />
                <ProFormText
                    width="md"
                    name="PO"
                    label="PO#"
                    placeholder=""
                    initialValue={focusDevannedRecord.PO}
                />
                <ProFormDigit
                    width="md"
                    name="totalQTY"
                    label="TotalQTY"
                    placeholder=""
                    initialValue={focusDevannedRecord.totalQTY}
                />
                <ProFormDigit
                    width="md"
                    name="loadedQTY"
                    label="LoadedQTY"
                    placeholder=""
                    initialValue={focusDevannedRecord.loadedQTY}
                />
                
                <ProFormDigit
                    width="md"
                    name="pallets"
                    label="Pallets"
                    placeholder=""
                    initialValue={focusDevannedRecord.pallets}
                />
                {role == 'admin' &&<ProFormText
                    width="md"
                    name="location"
                    label="Location"
                    placeholder=""
                    initialValue={focusDevannedRecord.location}
                />}
                <ProFormDatePicker initialValue={focusDevannedRecord.deliveryDate} name="deliveryDate" label="DeliveryDate" fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }}/>
                <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                <Row>
                    <ProFormTextArea label="ChinaNote" name="PO_chinaNotes" fieldProps={{ rows: 7, cols: 35 }} initialValue={focusDevannedRecord.PO_chinaNotes} />
                    <ProFormTextArea label="USNote" name="PO_US_Notes" fieldProps={{ rows: 7, cols: 35 }} initialValue={focusDevannedRecord.PO_US_Notes} />
                </Row>
            </ProForm.Group>
        </ModalForm>
    );
};