import { Button, Col, Form, Input, Row, Select, message } from "antd"
import axios from "axios"
import { useRecoilState, useRecoilValue } from "recoil"
import { roleListState, userTableLoadingState } from "./recoil.state"
import { UserPageTable } from "./userPageTable"
import { addUserUrl, archivedItemList,pendingItemList } from "./api"
import { useEffect, useState } from "react"
import _ from 'lodash'
import { UserUpdateModel } from "./userUpdate"
type ShowColumn = {
    shipper: string,
    container: string,
    ETD: string,
    ETA: string,
    type: string
    invoiceId: string,
    key: string
}
type DataType = {
    key: string
}
export const UserPage = () => {
    const [userTableLoading, setUserTableLoading] = useRecoilState(userTableLoadingState)
    const roles = useRecoilValue(roleListState)
    const [customers, setCustomers] = useState([])
    const [shippers, setShippers] = useState([])
    const [form] = Form.useForm();
    //isFillFinish
    const [isFillFinish, setIsFillFinish] = useState(false)
    const onFinish = (values) => {
        setUserTableLoading(true)
        axios.post(addUserUrl, { values }).then(res => {
            message.success('add success')

        }).catch(e => {

        }).finally(() => {
            setUserTableLoading(false)
        })
    }
    useEffect(() => {
        const role = localStorage.getItem('role')
        axios.get(archivedItemList).then(resArchivedItemList => {
            var dataArchived = resArchivedItemList.data
            axios.get(pendingItemList).then(res => {
                var { data } = res
                data.customers = data.customers.concat(dataArchived.customers)
                if (role == 'admin') {
                    data.shippers = data.shippers.concat(dataArchived.shippers)
                }
                for (let key in data) {
                    data[key] = data[key].filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
                }
                setCustomers(data.customers.map(it => ({ label: it, value: it })))
                if (role == 'admin') {
                    setShippers(data.shippers.map(it => ({ label: it, value: it })))
                }

            }).catch(e => {

            })

        }).catch(e => {

        })

    }, [])

    const onValuesChange = (changedValues, allValues) => {
        const isFillFinishVal = !(_.isEmpty(form.getFieldValue('password')) || _.isEmpty(form.getFieldValue('email')) || _.isEmpty(form.getFieldValue('role')))
        setIsFillFinish(isFillFinishVal)
    }
    return <>
        <Form
            name="search"
            onFinish={onFinish}
            form={form}
            style={{ marginTop: '20px' }}
            onValuesChange={onValuesChange}
        >
            <Row>
                <Col span={4} offset={0}>
                    <Form.Item name="email" label="UserName">
                        <Input allowClear={true} />
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item name="password" label="Password">
                        <Input allowClear={true} />
                    </Form.Item>
                </Col>


                <Col span={4} offset={1}>
                    <Form.Item name="shipper" label="Forwarder">
                        <Select showSearch allowClear={true} options={shippers} />
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                        />
                    </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                    <Form.Item name="role" label="Role">
                        <Select
                            showSearch
                            allowClear={true}
                            options={roles}
                        />
                    </Form.Item>
                </Col>

            </Row>

            <Row justify="end" >
                <Col  >
                    <Form.Item  >
                        <Button disabled={!isFillFinish} type="primary" htmlType="submit">Add</Button >
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <UserUpdateModel />
        <UserPageTable />

    </>
}


