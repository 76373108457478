import { useRecoilState, useRecoilValue } from "recoil"
import { roleState } from "../stateManage"
import { Popover, Typography } from "antd"
import { ScheduleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { dispatchEditModelIsOpenState, editModelIsOpenState, focusDevannedRecordState } from "./recoil.state";
import _ from 'lodash'

export const useInhousedTableColumn = () => {
    const role = useRecoilValue(roleState)
    const [editModelIsOpen, setEditModelIsOpen] = useRecoilState(editModelIsOpenState)
    const [dispatchEditModelIsOpen, setDispatchEditModelIsOpen] = useRecoilState(dispatchEditModelIsOpenState)
    const [focusDevannedRecord, setFocusDevannedRecord] = useRecoilState(focusDevannedRecordState)

    const dispatchedClick = (record) => {
        setDispatchEditModelIsOpen(true)
        setFocusDevannedRecord(record)
    }

    const eidtClick = (record) => {
        setEditModelIsOpen(true)
        setFocusDevannedRecord(record)
    }


    const columnDefaultWidth = 140
    //
    // ['TotalQTY', 'totalQTY', 120], ['Palletization', 'palletization']
    let columnsT = [['Forwarder', 'shipper', 120],['Bill#', 'billNumber', 160], ['CNTR/AWB#', 'containerId'],
    ['Customer', 'customer', 160], ['PO#', 'PO'], ['Loaded Q’ty', 'loadedQTY'], ['Pallets', 'pallets', 100],
    ['Est. Del. Date', 'deliveryDate'], ['Est. Del. Time', 'deliveryTime'], ['Location', 'location',150],['Dev. Date', 'devannedDate'], ['Storage(days)', 'storage'],  ['China Notes', 'PO_chinaNotes', 130], ['US Notes', 'PO_US_Notes', 120]]

    if (role == 'user') {
        _.remove(columnsT, it => it[1] == 'shipper')
    }
    if (role == 'customer') {
        _.remove(columnsT, it => it[1] == 'shipper')
        _.remove(columnsT, it => it[1] == 'customer')
    }
    let columns = columnsT.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth
        if (i == 0) {
            return {
                title: it[0],
                dataIndex: it[1],
                key: i,
                fixed: 'left',
                width: role == 'admin' ? width : null,
            }
        }
        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            width: role == 'admin' ? width : null,
            // sorter: true,
            onCell: (record) => ({
                record,
                title: it[0],
                dataindex: it[1],
            }),
            render: (text, record, index) => {
                try{
                    if (String(it[1]) == 'storage') {
                        let startDate = dayjs(record.devannedDate)
                        let endDate = dayjs()
                    
                        return endDate.diff(startDate, 'day') 
                    }
                }catch(e){
                    console.log(e)
                    console.log(record)
                    return '-'
                }
                
                if (text !== 0 && !text) {
                    return '-'
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                if (String(it[0]).includes('Time')) {
                    return text.slice(0, 5)
                }
                
                if (String(it[0]).includes('Load') && Number(record.loadedQTY) != Number(record.totalQTY)) {
                    return <strong style={{ color: '#ff0000', }}>{`${record.loadedQTY}/${record.totalQTY}`}</strong>
                }
                return text
            }
        }
    }) as any


    columns.push({
        title: 'Action',
        dataIndex: 'action',
        // with: 80,
        fixed: 'right',
        key: -1,
        render: (_: any, record) => {
            return <>
                {role == 'admin' ? <Typography.Link style={{ marginRight: '10px' }} onClick={e => dispatchedClick(record)}>
                    Delivery
                </Typography.Link> : null}

                <Typography.Link style={{ marginRight: '10px' }} onClick={e => eidtClick(record)}>
                    Edit
                </Typography.Link>
            </>
        },
    })
    // 找到Est. Del. Date
    var deliveryDateCol = columns.find(it => it.dataIndex == 'deliveryDate')
    // 日期排序Est. Del. Date
    deliveryDateCol.sorter = (a, b) => {
        // 如果 a.deliveryDate 为空，将 a 排到后面
        if (!a.deliveryDate) return -1;
        // 如果 b.deliveryDate 为空，将 b 排到后面
        if (!b.deliveryDate) return 1;
        return new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime()
    }
    // 找到Dev. Date
    var createdAtCol = columns.find(it => it.dataIndex == 'devannedDate')
    // 日期排序Dev. Date
    createdAtCol.sorter = (a, b) => {
        return new Date(a.devannedDate).getTime() - new Date(b.devannedDate).getTime()
    }

    return columns
}