import { useRecoilState } from "recoil";
import { deliveryPageActionListState, editItemsModelIsOpenState, selectedDeliveredRecordsState } from "./recoil.state";
import { DrawerForm, ProFormDatePicker, ProFormDigit, ProFormGroup, ProFormList, ProFormText, ProFormTimePicker } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { bulkEditDeliveredItemsUrl } from "./api";
import axios from "axios";
import { Col, Form, Row, message } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DeliveredAction } from "./type";



const title = 'Edit'
export const EditDeliveryItemsDrawer = () => {
    const [selectedDeliveredRecords, setSelectedDeliveredRecords] = useRecoilState(selectedDeliveredRecordsState);

    const [formLoading, setFormLoading] = useState(false)
    const [editItemsModelIsOpen, setEditItemsModelIsOpen] = useRecoilState(editItemsModelIsOpenState)
    const [deliveredActionList, setDeliveredActionList] = useRecoilState(deliveryPageActionListState)

    const [form] = Form.useForm();
    const [selected, setSelected] = useState([])
    useEffect(() => {
        if (selectedDeliveredRecords.length == 0) {
            form.setFieldValue('records', [])
            return
        }
        let recds = JSON.parse(JSON.stringify(selectedDeliveredRecords))

        form.setFieldValue('records', recds)
        return () => {
            form.setFieldValue('records', [])
        }
    }, [editItemsModelIsOpen])
    const openChange = (t) => {
        setEditItemsModelIsOpen(t)
    }
    const bulkEditClick = async (values) => {
        setFormLoading(true)
        
        let records = values.records
        if(values.customerRealName){
            records = records.map(it => {
                it.customer = values.customerRealName
                delete it.key
                return it
            })
        }
        axios.put(bulkEditDeliveredItemsUrl, {
            records
        }).then(res => {
            message.success('submit success')
            //更新表格
            var deliveredActions: DeliveredAction[] = JSON.parse(JSON.stringify(deliveredActionList))
            deliveredActions.push('bulkEdit')
            setDeliveredActionList(deliveredActions)
        }).catch(e => {

        }).finally(() => {
            setFormLoading(false)
        })
        return true
    }
    return <>{editItemsModelIsOpen &&
        <DrawerForm
            loading={formLoading}
            width={1300}
            onOpenChange={setEditItemsModelIsOpen}
            title={title}
            form={form}
            open={editItemsModelIsOpen}
            onFinish={bulkEditClick}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
        >

            <Row>
                <Col span={3} >
                    <ProFormText
                        width="md"
                        name="customerRealName"
                        label="Customer Name"
                        placeholder=""
                    />
                </Col>
            </Row>

            <ProFormList
                name="records"
                min={1}
                copyIconProps={false}
                creatorButtonProps={false}
                // creatorRecord={{ name: '', items: [{ name: '' }] }}
                initialValue={selected}
                deleteIconProps={{
                    Icon: CloseCircleOutlined,
                    tooltipText: 'Delete',
                }}
            >

                <ProFormGroup key="group">
                    <Row>
                        <Col span={4}>
                            <ProFormText
                                width="md"
                                name="billNumber"
                                label="Bill#"
                                placeholder=""
                            // disabled
                            // initialValue={it.billNumber}
                            />
                        </Col>
                        <Col span={4}>
                            <ProFormText
                                width="md"
                                name="customer"
                                label="Customer"
                                placeholder=""
                            // disabled
                            // initialValue={it.vendor}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormText
                                disabled
                                width="md"
                                name="PO"
                                label="PO#"
                                placeholder=""
                            // initialValue={it.PO}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormDigit
                                disabled
                                width="md"
                                name="loadedQTY"
                                label="LoadedQTY"
                                placeholder=""
                            // initialValue={it.loadedQTY}
                            />
                        </Col>
                        <Col span={2}>
                            <ProFormDigit
                                width="md"
                                name="pallets"
                                label="Pallets"
                                disabled
                                placeholder=""
                            // initialValue={it.pallets}
                            />
                        </Col>
                        <Col >
                            <ProFormDatePicker disabled name="deliveryDate" label="Est. Del. Date" fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }}/>
                        </Col>
                        <Col >
                            <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                        </Col>
                    </Row>
                </ProFormGroup>
            </ProFormList>

        </DrawerForm>}
    </>
}