import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
} from '@ant-design/pro-form';
import {  Form,  message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containerEditModelIsOpenState, containerPageActionListState, focusContainerRecordState } from '../recoil.state';
import { instructionOptionState } from '../../stateManage';
import axios from 'axios';
import { saveContainerEditUrl } from '../api';
import { useEffect } from 'react';
import { ContainerAction } from '../type';

export const ContainerEditModel = () => {
    const instructionOptions = useRecoilValue(instructionOptionState)
    const [focusContainerRecord, setFocusContainerRecord] = useRecoilState(focusContainerRecordState)
    const [containerEditModelIsOpen, setContainerEditModelIsOpen] = useRecoilState(containerEditModelIsOpenState)
    const [containerPageActionList,setContainerPageActionList] = useRecoilState(containerPageActionListState);

    const [form] = Form.useForm();
    const containerEditSave=(values)=>{
        axios.put(saveContainerEditUrl,{
            values,id:focusContainerRecord._id
        }).then(res=>{
            message.success('Edit Success')
            //更新表格
            var containerPageActions:ContainerAction[] = JSON.parse(JSON.stringify(containerPageActionList));
            containerPageActions.push('containerEdit')
            setContainerPageActionList(containerPageActions)
        }).catch(e=>{
            
        }).finally(()=>{
           
        })
    }
    useEffect(()=>{
        form.setFieldsValue({...focusContainerRecord})
    },[containerEditModelIsOpen])
    return (
        <ModalForm
            open={containerEditModelIsOpen}
            title={`CNTR/AWB# ${focusContainerRecord.containerId}`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(p) => setContainerEditModelIsOpen(p)}
            submitTimeout={2000}
            onFinish={async (values) => {
                containerEditSave(values)
                return true;
            }}
        >
            <ProForm.Group>
                <ProFormText
                    width="md"
                    name="containerId"
                    label="CNTR/AWB#"
                    placeholder=""
                    initialValue={focusContainerRecord.containerId}
                />
                <ProFormText
                    width="md"
                    name="operator"
                    label="Operator"
                    placeholder=""
                    initialValue={focusContainerRecord.operator}
                />
                <ProFormText
                    width="md"
                    name="billNumber"
                    label="Bill#"
                    placeholder=""
                    initialValue={focusContainerRecord.billNumber}
                />
                <ProFormSelect
                    width="md"
                    name="instruction"
                    label="Instruction"
                    placeholder=""
                    initialValue={focusContainerRecord.instruction}
                    options={instructionOptions}
                />
               
                <ProFormDatePicker name="ETA" label="ETA" initialValue={focusContainerRecord.ETA} fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }}/>
                <ProFormDatePicker name="ETD" label="ETD" initialValue={focusContainerRecord.ETD} fieldProps={{ format: 'MM/DD/YYYY', changeOnBlur: true, showNow: false }}/>
                </ProForm.Group>
                <ProForm.Group>
                <ProFormTextArea label="Shipment Notes" name="chinaNotes" fieldProps={{rows:7,cols:35}}  initialValue={focusContainerRecord.chinaNotes}/>

                
            </ProForm.Group>
        </ModalForm>
    );
};