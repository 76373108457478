import {atom} from 'recoil'

 export const userTableDataState=atom({
   key:'userTableDataState',
   default:[] as any[]
})

export const userTableLoadingState=atom({
  key:'userTableLoadingState',
  default:false
})

export const roleListState=atom({
  key:'roleListState',
  default:[{ value: 'user', label: 'forwarder' },
    { value: 'admin', label: 'admin' },
    { value: 'customer', label: 'customer' }]
})

export const focusUserRecordState=atom({
  key:'focusUserRecordState',
  default:{}  as any
})
export const userUpdateModelIsOpenState=atom({
  key:'userEditModelIsOpenState',
  default:false
})
export const userActionListState=atom({
  key:'userActionListState',
  default:[]  as any
})