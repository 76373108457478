import { Button, Drawer, Popover, Space, Table, Typography } from "antd"
import { SetStateAction, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { containerDetailDrawerIsOpenState, focusContainerRecordState } from "./recoil.state"
import { DetailEditModel } from "./components/detailEditModel"
import printJS from "print-js"
import { FillPalletsModel } from "./components/fillPalletsModel"
import { usePageDetailTableColumn } from "./hooks"

type DataType = {
  PO: string,
  boxes: number,
  pallets: number,
  deliveryDate: string,
  deliveryTime: string,
  customer: string,
  ChinaNote: string
  USNote: string
}

export const ContainerDetailDrawer = () => {
  const [dataSource, setDataSource] = useState([])
  const focusContainerRecord = useRecoilValue(focusContainerRecordState)
  const columns = usePageDetailTableColumn(focusContainerRecord)

  const [containerDetailDrawerIsOpen, setContainerDetailDrawerIsOpen] = useRecoilState(containerDetailDrawerIsOpenState)

  useEffect(() => {
    if(Object.keys(focusContainerRecord).length ==0){
      return
    }
    var POs = JSON.parse(JSON.stringify(focusContainerRecord.POs))
    POs =POs.sort((a, b) =>{
      //if customer is  empty,  return 
      if(!a.customer || !b.customer){
        return 
      }
      if(a.customer==b.customer){
        return a.PO.localeCompare(b.PO)
      }else{
        return a.customer.localeCompare(b.customer)
      }
    })
    POs = POs.map((it: any) => ({ ...it, key: it.id }))
    setDataSource(POs)

  }, [containerDetailDrawerIsOpen])

  const detailClose = () => setContainerDetailDrawerIsOpen(false)

  const printClick = () => {
    const jsonString = JSON.stringify(dataSource);
    var data = JSON.parse(jsonString);
    data.forEach(it => {
      if(!it.deliveryDate){
        it.deliveryDate='-'
      }
      if(!it.deliveryTime){
        it.deliveryTime='-'
      }
      if(!it.PO_chinaNotes){
        it.PO_chinaNotes='-'
      }
    })

    const prop = [
      { field: 'customer', displayName: 'Customer' },
      { field: 'PO', displayName: 'PO' },
      { field: 'loadedQTY', displayName: 'Loaded Q’ty' },
      { field: 'totalQTY', displayName: 'Total Q’ty' },
      { field: 'deliveryDate', displayName: 'Est. Del. Date' },
      { field: 'deliveryTime', displayName: 'Est. Del. Time' },
      { field: 'PO_chinaNotes', displayName: 'PO chinaNotes' },
    ]
    const gridStyle = 'border: 1px solid lightgray; margin-bottom: -1px;'
      + 'text-align:center;'
      + 'font-size:18px;'

    printJS({
      printable: data, type: 'json',
      header: focusContainerRecord.containerId,
      properties: prop,
      gridStyle
    })
  }
  return <>
    <DetailEditModel />
    <FillPalletsModel/>
    <Drawer extra={
      <Button type="primary" onClick={printClick}>
        Print
      </Button>
    } width={1100} title={'Detail #' + focusContainerRecord.containerId} placement="right" onClose={detailClose} open={containerDetailDrawerIsOpen}>
      <Table size={'small'}
        id={'pendingDetailDrawerTable'}
        pagination={false}
        components={{
          // body: {
          //     cell: EditDetailCell,
          // },
        }}
        columns={columns} dataSource={dataSource}></Table>
    </Drawer>
  </>
}
