import { Button, Col, Row, Table, message } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { deleteDevannedItemsUrl, devannedSearchUrl } from "./api"
import { useRecoilState, useRecoilValue } from "recoil"
import { bulkTransferModelIsOpenState, devannedTableDataState, devannedTableLoadingState, dispatchEditModelIsOpenState, dispatchManyEditModelIsOpenState, editItemsModelIsOpenState, editModelIsOpenState, focusDevannedRecordState, inHousedActionListState, inHousedSearchValuesState, selectedDevannedRecordsState } from "./recoil.state"
import { instructionOptionState, roleState } from "../stateManage"
import { useInhousedTableColumn } from "./hooks"
import { InHousedAction } from "./type"
import dayjs from 'dayjs';

export const InhousedPageTable = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(devannedTableDataState)
    const [devannedTableLoading, setDevannedTableLoading] = useRecoilState(devannedTableLoadingState)
    const [focusRecord, setFocusRecord] = useRecoilState(focusDevannedRecordState)
    const [dispatchManyEditModelIsOpen, setDispatchManyEditModelIsOpen] = useRecoilState(dispatchManyEditModelIsOpenState)
    const [bulkTransferModelIsOpen, setbulkTransferModelIsOpen] = useRecoilState(bulkTransferModelIsOpenState)
    const instructionOptions = useRecoilValue(instructionOptionState)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedDevannedRecords, setSelectedDevannedRecords] = useRecoilState(selectedDevannedRecordsState);
    const [editItemsModelIsOpen, setEditItemsModelIsOpenState] = useRecoilState(editItemsModelIsOpenState)
    const [inHousedActionList, setInHousedActionList] = useRecoilState(inHousedActionListState)
    const inHousedSearchValues = useRecoilValue(inHousedSearchValuesState)
    const columns = useInhousedTableColumn()

    const dispatchedManyClick = () => {
        setDispatchManyEditModelIsOpen(true)

    }
    const bulkTransferClick = () => {
        setbulkTransferModelIsOpen(true)

    }
    useEffect(() => {
        setDevannedTableLoading(true)
        setSelectedRowKeys([])


        var values = JSON.parse(JSON.stringify(inHousedSearchValues))
        if (values.createdAt) {
            values.createdAt = dayjs(values.createdAt).format('MM/DD/YYYY')
        }
        if (values.ETA) {
            values.ETA = dayjs(values.ETA).format('MM/DD/YYYY')
        }
        if (values.ETD) {
            values.ETD = dayjs(values.ETD).format('MM/DD/YYYY')
        }
        if (values.deliveryDate) {
            let date = {start:null,end:null}
            date.start = dayjs(values.deliveryDate[0]).format('MM/DD/YYYY')
            date.end = dayjs(values.deliveryDate[1]).format('MM/DD/YYYY')
            values.deliveryDate = date
        }
        if (values.deliveryTime) {
            values.deliveryTime = dayjs(values.deliveryTime).format('HH:mm')
        }
        if (values.loadedQTY == "") {
            delete values.loadedQTY
        }
        axios.post(devannedSearchUrl, { values }).then(res => {
            const dataRes = res.data.map(it => {
                if (it.deliveryTime) {
                    it.deliveryTime = it.deliveryTime.slice(0, 5)
                }
                return { ...it, key: it._id }
            })
            //sort by devannedDate
            dataRes.sort((a, b) => {
                //if empty, put it to the end
                if (!a.devannedDate) {
                    return 1
                }
                if (!b.devannedDate) {
                    return -1
                }
                return dayjs(a.devannedDate).isBefore(dayjs(b.devannedDate)) ? 1 : -1
            })
            
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setDevannedTableLoading(false)
        })

    }, [inHousedSearchValues, inHousedActionList])

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedDevannedRecords(selectedRows)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const deleteClick = () => {
        axios.post(deleteDevannedItemsUrl, {
            ids: selectedDevannedRecords.map(it => it._id)
        }).then(res => {
            message.success('delete success')

            //更新表格
            var inHousedActions: InHousedAction[] = JSON.parse(JSON.stringify(inHousedActionList))
            inHousedActions.push('bulkDelete')
            setInHousedActionList(inHousedActions)
        }).catch(e => {

        })
    }
    const editClick = () => {
        setEditItemsModelIsOpenState(true)
    }
    const totalBox = tableData.reduce((acc, it) => {
        return acc + it.loadedQTY
    }, 0)
    const totalPallets = tableData.reduce((acc, it) => {
        if (!it.pallets) {
            return acc + 0
        }
        return acc + it.pallets
    }, 0)
    return <>
        <Row justify='end' style={{ marginTop: '10px', marginRight: '0px' }}>
            {role == 'admin' ? <>
                <Col style={{ marginLeft: '20px' }}>
                    <Button type="primary" onClick={editClick} disabled={!hasSelected} >
                        Bulk Edit
                    </Button>
                </Col>
               
                <Col style={{ marginLeft: '20px' }} >
                    <Button type="primary" onClick={dispatchedManyClick} disabled={!hasSelected || role != 'admin'} >
                        Bulk Delivery
                    </Button>
                </Col>
                <Col style={{ marginLeft: '20px' }}>
                    <Button type="primary" onClick={bulkTransferClick} disabled={!hasSelected} >
                        Bulk Transfer
                    </Button>
                </Col>
                <Col style={{ marginLeft: '20px' }}>
                    <Button type="primary" onClick={deleteClick} disabled={!hasSelected} >
                        Delete
                    </Button>
                </Col>
            </> : null}
            <Col style={{ marginLeft: '10px' }}><span style={{ lineHeight: '32px' }}>
                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span></Col>
        </Row>
        <Row style={{ fontWeight: '700' }}>
            <Col style={{ lineHeight: '50px' }}>{`TotalBoxes:${totalBox}`}</Col>
            <Col style={{ marginLeft: '20px', lineHeight: '50px' }}>{` TotalPallets:${totalPallets}`}</Col>
        </Row>
        <Table sticky={true} size={'small'} pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
            rowSelection={rowSelection} loading={devannedTableLoading} scroll={{ x: 2100, y: 1000 }} columns={columns} dataSource={tableData} />
    </>

}
