import { Popover, Typography } from "antd"
import { ScheduleTwoTone } from '@ant-design/icons';
import { containerTableDataType } from "./type";
import { roleState } from "../stateManage";
import { useRecoilState } from "recoil";
import { archivedDetailDrawerIsOpen, focusArchivedRecordState } from "./recoil.state";
import dayjs from 'dayjs';
import _ from 'lodash'
export const useArchivedTableColumn = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useRecoilState(archivedDetailDrawerIsOpen)
    const [focusRecord, setFocusRecord] = useRecoilState(focusArchivedRecordState)

    const detailClick = (record) => {
        setIsDetailDrawerOpen(true)
        setFocusRecord({ ...record })
    }

    const columnDefaultWidth = 140
    var columns = [['Operator', 'operator'], ['Bill#', 'billNumber', 170], ['CNTR/AWB#', 'containerId', 170],
    ['Shipment Q’ty', 'shipmentQTY', 150], ['Instruction', 'instruction', 180], ['ETD', 'ETD'], ['ETA', 'ETA'], ['Dev. Date', 'devannedDate', 170], ['Shipment Notes', 'chinaNotes']]
    if (role == 'admin') {
        columns.unshift(['Forwarder', 'shipper'])
    }
    let mergedColumns: any = columns.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth
        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            width: role == 'admin' ? width : null,
            // sorter: true,
            onCell: (record: containerTableDataType) => ({
                record,
                title: it[0],
                dataindex: it[1],
            }),
            render: (text, record, index) => {
                if (!text && text !== 0) {
                    return <>-</>
                }
                if (it[1] == 'ETD' || it[1] == 'ETA' || it[1] == 'devannedDate') {
                    return dayjs(text).format('MM/DD/YYYY')
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                return <>{text}</>
            }
        }

    })

    mergedColumns.push({
        title: 'Action',
        dataIndex: 'action',
        with: 80,
        fixed: 'right',
        key: -1,
        render: (_: any, record) => {
            return <>
                <Typography.Link style={{ marginRight: '10px' }} onClick={e => detailClick(record)}>
                    detail
                </Typography.Link>
            </>
        },
    })
    // 找到ETD列
    var ETDColumn = mergedColumns.find(it => it.dataIndex == 'ETD')
    // 日期排序
    ETDColumn.sorter = (a, b) => {
        return new Date(a.ETD).getTime() - new Date(b.ETD).getTime()
    }
    // 找到ETA列
    var ETAColumn = mergedColumns.find(it => it.dataIndex == 'ETA')
    // 日期排序ETA
    ETAColumn.sorter = (a, b) => {
        return new Date(a.ETA).getTime() - new Date(b.ETA).getTime()
    }
    //找到Dev. Date列
    var devDateColumn = mergedColumns.find(it => it.dataIndex == 'devannedDate')
    // 日期排序Dev. Date
    devDateColumn.sorter = (a, b) => {
        return new Date(a.devannedDate).getTime() - new Date(b.devannedDate).getTime()
    }
    return mergedColumns
}


export const archivedDetailTableColumn = (devannedDate) => {
    const columns = [['Customer', 'customer'], ['PO#', 'PO'], ['Status', 'status'], ['Loaded Q’ty', 'loadedQTY'], ['Total Q’ty', 'totalQTY'], ['Pallets', 'pallets'], ['Del. Date', 'deliveryDate'],
    ['Del. Time', 'deliveryTime'], ['Location', 'location'],['Storage(days)', 'storage', 120], ['China Notes', 'PO_chinaNotes'], ['US Notes', 'PO_US_Notes']
    ]
    const role = localStorage.getItem('role')
    
    if (role == 'user') {
    }
    if (role == 'customer') {
        _.remove(columns, it => it[1] == 'customer')
    }

    var cols: any = columns.map((it, i) => {

        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            // width: width,
            onCell: (record) => ({
                record,
                title: it[0],
                dataindex: it[1],
            }),
            render: (text, record, index) => {
                let startDate = dayjs(devannedDate)
                let endDate = dayjs()
                
                if (String(it[1]) == 'storage') {
                    if(record.status=='Delivered'){
                        endDate = dayjs(record.deliveryDate)
                    }
                    
                    return endDate.diff(startDate, 'day')
                }
                if (!text && text !== 0) {
                    return <>-</>
                }
                if (it[0] == 'Del. Date') {
                    return dayjs(text).format('MM/DD/YYYY')
                }
                if (it[0] == 'Del. Time') {
                    return text.slice(0, 5)
                }

                if (String(it[0]).includes('Notes')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                return <>{text}</>
            }
        }
    })

    return cols
}