import { Button, Col, Row, Table, Typography, message } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { deliveredTableLoadingState, deliveryPageActionListState, deliverySearchValuesState, dispatchedTableDataState, editItemsModelIsOpenState, selectedDeliveredRecordsState, summaryDrawerIsOpenState } from "./recoil.state"
import { instructionOptionState, roleState } from "../stateManage"
import { back2DevannedUrl, deliveredSearchUrl } from "./api"
import { useDeliveryColumn } from "./hooks"
import dayjs from 'dayjs';

export const DispatchedPageTable = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(dispatchedTableDataState)
    const [loading, setLoading] = useState(false);
    const [deliveredTableLoading, setDeliveredTableLoading] = useRecoilState(deliveredTableLoadingState)
    const [deliverySearchValues, setDeliverySearchValues] = useRecoilState(deliverySearchValuesState)
    const [deliveryPageActionList, setDeliveryPageActionList] = useRecoilState(deliveryPageActionListState)
    const [summaryDrawerIsOpen, setSummaryDrawerIsOpen] = useRecoilState(summaryDrawerIsOpenState)
    const [editItemsModelIsOpen, setEditItemsModelIsOpenState] = useRecoilState(editItemsModelIsOpenState)
    const [selectedDeliveredRecords, setSelectedDeliveredRecords] = useRecoilState(selectedDeliveredRecordsState);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columns = useDeliveryColumn()
    useEffect(() => {
        setSelectedRowKeys([])
        setDeliveredTableLoading(true)
        var values = JSON.parse(JSON.stringify(deliverySearchValues))
        if (values.ETA) {
            values.ETA = dayjs(values.ETA).format('MM/DD/YYYY')
        }
        if (values.ETD) {
            values.ETD = dayjs(values.ETD).format('MM/DD/YYYY')
        }
        if (values.deliveryDate) {
            let date = {start:null,end:null}
            date.start = dayjs(values.deliveryDate[0]).format('MM/DD/YYYY')
            date.end = dayjs(values.deliveryDate[1]).format('MM/DD/YYYY')
            values.deliveryDate = date
        }
        if (values.deliveryTime) {
            values.deliveryTime = dayjs(values.deliveryTime).format('HH:mm')
        }
        if (values.loadedQTY == "") {
            delete values.loadedQTY
        }
        axios.post(deliveredSearchUrl, { values }).then(res => {
            var dataRes = res.data
            dataRes.forEach(it => {
                it.key = it._id
                if (it.deliveryTime) {
                    it.deliveryTime = it.deliveryTime.slice(0, 5)
                }
            })
            //sort by deliveryDate
            dataRes.sort((a, b) => {
                //if empty, put it to the end
                if (!a.deliveryDate) {
                    return 1
                }
                if (!b.deliveryDate) {
                    return -1
                }
                return dayjs(a.deliveryDate).isBefore(dayjs(b.deliveryDate)) ? 1 : -1
            })
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setDeliveredTableLoading(false)
        })

    }, [deliverySearchValues, deliveryPageActionList])
    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedDeliveredRecords(selectedRows)

    };
    var rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const invoiceClick = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };
    const hasSelected = selectedRowKeys.length > 0;
    const back2DevannedClick = () => {

        setLoading(true)
        axios.put(back2DevannedUrl, {
            ids: selectedDeliveredRecords.map(it => it._id)
        }).then(res => {
            message.success('Back to devanned successfully')
            //更新表格
            var containerPageActions = JSON.parse(JSON.stringify(deliveryPageActionList));
            containerPageActions.push('back2Devanned')
            setDeliveryPageActionList(containerPageActions)
        }).catch(e => {

        }).finally(() => {
            setLoading(false)
        })
    }
    const summaryClick = () => {
        setSummaryDrawerIsOpen(true)
    }
    const bulkEditClick = () => {
        setEditItemsModelIsOpenState(true)
    }
    return <>
        <Row justify='end' style={{ margin: '10px', marginRight: 0 }}>{
            role == 'admin' && <>
                <Col style={{ marginLeft: '20px' }} >
                    <Button type="primary" onClick={bulkEditClick} disabled={!hasSelected || role != 'admin'} >
                        Bulk Edit
                    </Button>
                </Col>
                <Col style={{ marginLeft: '20px' }}>
                    <Button type="primary" onClick={back2DevannedClick} disabled={!hasSelected || role != 'admin'} loading={loading}>
                        Back To In-housed
                    </Button>
                </Col>

                {/* <Col style={{ marginLeft: '20px' }}>
                    <Button type="primary" onClick={invoiceClick} disabled={!hasSelected || role != 'admin'} loading={loading}>
                        Make Invoice
                    </Button>
                </Col> */}
            </>}
            <Col style={{ marginLeft: '20px' }}>
                <Button type="primary" onClick={summaryClick} disabled={!deliverySearchValues['deliveryDate'] || tableData.length == 0 || deliveredTableLoading} >
                    Summary By Date
                </Button>
            </Col>
            {
                hasSelected ? <span style={{ marginLeft: '10px', lineHeight: '32px' }}>
                    {`Selected ${selectedRowKeys.length} items`}
                </span> : null
            }
        </Row>
        <Row >
            <Table size={'small'}
                sticky={true}
                pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
                loading={deliveredTableLoading} rowSelection={role == 'admin' ? rowSelection : null} scroll={{ x: 1600, y: 1000 }} columns={columns} dataSource={tableData} />
        </Row>
    </>

}