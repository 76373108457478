import { Typography } from "antd";
import { containerTableDataType } from "../type";
import { useRecoilState } from "recoil";
import { containerDetailDrawerIsOpenState, containerEditModelIsOpenState, devanningDrawerIsOpenState, focusContainerRecordState, tableDataState } from "../recoil.state";
import { useState } from "react";
import { roleState } from "../../stateManage";


export const ActionCell =({record})=>{
    const [role, setRole] = useRecoilState(roleState)
    const [detailDrawerIsOpen, setDetailDrawerIsOpen] = useRecoilState(containerDetailDrawerIsOpenState)
    const [devanningDrawerIsOpen, setDevanningDrawerIsOpen] = useRecoilState(devanningDrawerIsOpenState)
    const [containerEditModelIsOpen, setContainerEditModelIsOpen] = useRecoilState(containerEditModelIsOpenState)
    const [focusRecord, setFocusRecord] = useRecoilState(focusContainerRecordState)
    const [tableData, setTableData] = useRecoilState(tableDataState)

    const [editingKey, setEditingKey] = useState('');

    const editClick = (record:containerTableDataType) => {
        // form.setFieldsValue({ name: '', age: '', address: '', ...record });
        setContainerEditModelIsOpen(true)
        setFocusRecord({...record,isEdit:true});
    };
    const detailClick = (record) => {
        setDetailDrawerIsOpen(true)
        setFocusRecord({...record,isEdit:false})
    }

    const devanningClick = (record: containerTableDataType) => {
        setDevanningDrawerIsOpen(true)
        setFocusRecord({...record,isEdit:false})
    }
   
    return  <>
        <Typography.Link style={{ marginRight: '10px' }} disabled={editingKey !== ''} onClick={e => detailClick(record)}>
            Detail
        </Typography.Link>
        <Typography.Link style={{ marginRight: '10px' }} onClick={e => editClick(record)}>
            Edit
        </Typography.Link>
        {role=='admin'? <Typography.Link disabled={editingKey !== ''} onClick={e => devanningClick(record)}>
            Devanning
        </Typography.Link>:null}
   </>
}