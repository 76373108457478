import { Button, Col, Row, Table, Typography, message } from "antd"
import axios from "axios"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { focusUserRecordState, userTableDataState, userTableLoadingState, userUpdateModelIsOpenState } from "./recoil.state"
import {  roleState } from "../stateManage"
import {  deleteUserUrl,getAllUserUrl } from "./api"

export const UserPageTable = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(userTableDataState)
    const [columns, setColumn] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [userTableLoading, setUserTableLoading] = useRecoilState(userTableLoadingState)
    const [userUpdateModelIsOpen, setUserUpdateModelIsOpen] = useRecoilState(userUpdateModelIsOpenState)
    const [focusUserRecord, setFocusUserRecordState] = useRecoilState(focusUserRecordState)


    useEffect(() => {
        setUserTableLoading(true)
        axios.get(getAllUserUrl).then(res => {
            res.data.forEach(it => { 
                it.key = it._id
            })
            setTableData(res.data)
        }).catch(e => {

        }).finally(() => {
            setUserTableLoading(false)
        })
        const updateClick = (record) => {
            setUserUpdateModelIsOpen(true)
            setFocusUserRecordState(record)
        }
        const columns = getColumns()
        role == 'admin'&&columns.push({
            title: 'Operations',
            dataIndex: 'Operations',
            key: -1,
            with: 80,
            fixed:'right',
            onCell: (record) => ({
                record,
                title: 'Action',
                dataindex: 'action',
            }),
            render: (_: any, record) => {
                return <Typography.Link style={{ marginRight: '10px' }} onClick={e => updateClick(record)}>
                    Update
                </Typography.Link>
            },
        })
        setColumn(columns)

    }, [])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    const deleteClick = () => {
        setLoading(true)
        axios.put(deleteUserUrl, {
            ids: selectedRowKeys
        }).then(res => {
            message.success('delete success')
        }).catch(e => {

        }).finally(() => {
            setLoading(false)
        })
    }
    
    
    return <>
        <Row justify='end' style={{ margin: '20px',marginRight:0 }} >{
            role == 'admin' ? <>
                <Col style={{ margin: '0 20px' }}>
                    <Button type="primary" onClick={deleteClick} disabled={!hasSelected || role != 'admin'} loading={loading}>
                        delete
                    </Button>
                </Col>
                <span style={{ lineHeight:'32px' }}>
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span></> : null}
        </Row>
        <Row >
            <Table size={'small'}
             sticky={true}
                pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
                loading={userTableLoading} rowSelection={rowSelection} scroll={{ x: 1600,y:1000 }} columns={columns} dataSource={tableData} />
        </Row>
    </>

}


function getColumns() {
    const columnDefaultWidth = 150
    let columnsT = [['User Name', 'email'],['Role', 'role'],   ['Forwarder', 'shipper'],['Customer', 'customer']]
    let columns = columnsT.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth
        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            // width: width
        }
    }) as any
    
    return columns
}